<template>
    <div v-if="notification" class="board-container">
        <table class="board-view">
            <tr>
                <th colspan="2">{{ notification.subject }}</th>
            </tr>
            <tr>
                <td colspan="2">
                    <!-- <span>작성자 : 관리자</span> &nbsp;&nbsp;&nbsp; -->
                    <v-row class="row--small">
                        <v-col cols="auto">
                            <span>{{$t('common.작성일')}} : {{ notification.createdAt.toDate() }}</span>
                        </v-col>
                        <v-col cols="auto">
                            <span>{{$t('common.조회수')}} : {{ notification.viewCount }}</span>
                        </v-col>
                    </v-row>
                </td>
            </tr>
            <tr v-if="notification.upload">
                <td colspan="2">
                    <v-row class="row--small">
                        <v-col cols="auto">
                            {{$t("common.attachments")}} : <v-icon>mdi-paperclip</v-icon>
                            <a @click="download(notification.upload, notification.upload.name.split('/').pop())" style="text-decoration:underline;">{{ notification.upload.name.split('/').pop() }}</a>
                        </v-col>
                    </v-row>
                </td>
            </tr>
            <tr>
                <td colspan="2" class="content_box">
                    <div v-html="notification.content.replace(/\n/g,'<br>')"></div>
                </td>
            </tr>
        </table>

        <table v-show="false" class="board-view-navi">
            <tr>
                <th width="20%">이전글</th>
                <td><a href="">이전 게시글 제목입니다.</a></td>
            </tr>
            <tr>
                <th width="20%">다음글</th>
                <td><a href="">다음 게시글 제목입니다.</a></td>
            </tr>
        </table>

        <div class="board-bottom">
            <v-row justify="end" class="row--x-small">
                <v-col v-show="false" cols="auto">
                    <v-row justify="end" class="row--x-small">
                        <v-col cols="auto">
                            <v-btn color="primary" class="w-100px">수정</v-btn>
                        </v-col>
                        <v-col cols="auto">
                            <v-btn outlined color="primary" class="w-100px">삭제</v-btn>
                        </v-col>
                        <v-col cols="auto">
                            <v-btn outlined color="primary" class="w-100px">답글</v-btn>
                        </v-col>
                    </v-row>
                </v-col>
                <v-spacer />
                <v-col cols="auto">
                    <v-btn outlined color="grey-d6" class="w-140px" @click="$router.go(-1)">{{$t("common.list")}}</v-btn>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import api from "@/api";
import download from "downloadjs";

export default {
	components: {
	},
    data() {
        return {
            notification: null
        };
    },
	mounted() {
		this.init();
	},
	methods: {
        download,
		async init() {
            try{
                var { notification } = await api.v1.center.notifications.get({ _id: this.$route.params._notification });
                if(notification.upload) notification.upload = await api.getResource(notification.upload);
                this.notification = notification;
            }
            catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
		}
	},
}
</script>
