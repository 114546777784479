<template>
	<client-page>
        <template v-slot:subHead>
            <sub-visual title="커뮤니티" src="/images/sub/sv-community.jpg"></sub-visual>
        </template>
        <template v-slot:contentsImmersive>
            <client-gnb className="sub-tab-wrap" tabTitle="커뮤니티" tabActive="공지사항"></client-gnb>
        </template>

        <v-container>
            <!-- 1. 게시판 리스트: 베이직 스킨 -->
            <notification-list v-if="!$route.params._notification"></notification-list>
            <notification-view v-else></notification-view>
            <!-- 2. 게시판 작성: 베이직 스킨 -->
            <!-- <board-basic-input></board-basic-input> -->
            <!-- 3. 게시판 뷰: 베이직 스킨 -->
            <!-- <board-basic-view></board-basic-view> -->
        </v-container>
	</client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import ClientGnb from "@/components/client/inc/client-gnb.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import NotificationList from "@/components/client/center/notifications/notification-list.vue";
import NotificationView from "@/components/client/center/notifications/notification-view.vue";

export default{
	components: {
        ClientPage,
        ClientGnb,
        SubVisual,
		NotificationList,
		NotificationView
	},
}
</script>
